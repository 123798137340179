"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// custom typefaces
require("typeface-montserrat");
require("typeface-merriweather"); // normalize CSS across browsers
// import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"
// Highlighting for code blocks
// import "prismjs/themes/prism.css"
// import "prism-themes/themes/prism-vs.css"
require("prism-themes/themes/prism-vsc-dark-plus.css"); // import "prism-themes/themes/prism-ghcolors.css"
require("@fortawesome/fontawesome-svg-core/styles.css");
