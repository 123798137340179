// extracted by mini-css-extract-plugin
export var description = "linkOgp-module--description--xP494";
export var host = "linkOgp-module--host--nmqj3";
export var imgContainer = "linkOgp-module--imgContainer--kbDX1";
export var main = "linkOgp-module--main--bXx1r";
export var ogpContainer = "linkOgp-module--ogpContainer--bh25d";
export var ogpImg = "linkOgp-module--ogpImg--T+VuD";
export var ogpLink = "linkOgp-module--ogpLink--eEzSE";
export var parent = "linkOgp-module--parent--jdAnb";
export var textContainer = "linkOgp-module--textContainer--GFYoY";
export var title = "linkOgp-module--title--+iRmB";