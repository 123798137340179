"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isViewReducer = void 0;
const isViewActions_1 = require("../actions/isViewActions");
const createStore_1 = require("../state/createStore");
const reducer = function (state, action) { if (state === void 0) {
    state = createStore_1.initialState;
} try {
    const exec = { [isViewActions_1.IsViewAction.CHANGE_VIEW_MODE]: () => changeViewMode(state, action.isListView) };
    return exec[action.type]();
}
catch (e) {
    console.error(e);
    return state;
} };
const changeViewMode = (state, isListView) => {
    return Object.assign({}, state, { isListView });
};
exports.isViewReducer = { reducer };
